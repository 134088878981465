import { graphql, useStaticQuery } from "gatsby";

const recentCollections = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const data = useStaticQuery(
    graphql`
      query {
        django {
          allCollections(first: 5, isFeatured: true) {
            edges {
              node {
                dressSet(first: 1) {
                  edges {
                    node {
                      dresspictureSet(first: 1) {
                        edges {
                          node {
                            id
                            processedPhotoSharp {
                              childImageSharp {
                                fluid {
                                  ...GatsbyImageSharpFluid
                                }
                              }
                            }
                            processedPhoto
                          }
                        }
                      }
                      id
                    }
                  }
                }
                name
                slug
                note
                id
                category {
                  slug
                }
              }
            }
          }
        }
      }
    `
  );
  return data;
};

export default recentCollections;
