import React from "react";
import Img from "gatsby-image";
import Flickity from "react-flickity-component";

import Layout from "../../layout/Layout";
import StyledLink from "../../components/styled-link/StyledLink";
import OfferGuide from "../../components/offer-guide/OfferGuide";
import BannerImage from "./assets/BannerImage";
import Modal from "react-modal";
import { TfiClose } from "react-icons/tfi";
import { useState } from "react";

import indexStyle from "./assets/Index.module.scss";
import "../../styles/flickity.scss";

import recentCollections from "../../queries/recentCollections";

const WarnModal = ({ isOpen, setIsOpen }) => (
  <Modal
    ariaHideApp={false}
    style={{
      content: {
        marginTop: "5em",
        borderRadius: "unset",
        // maxHeight: "15em",
      },
    }}
    isOpen={isOpen}
  >
    <div style={{ height: "100%" }}>
      <div style={{ cursor: "pointer" }} onClick={() => setIsOpen(false)}>
        <TfiClose />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
          overflowY: "hidden",
        }}
      >
        <OfferGuide />
      </div>
    </div>
  </Modal>
);

const IndexPage = () => {
  const [isOpen, setIsOpen] = useState(true);
  const recentCollectionsQuery = recentCollections();
  const collectionsList = recentCollectionsQuery.django.allCollections.edges;

  const categoriesSliderOptions = {
    cellAlign: "left",
    prevNextButtons: false,
    pageDots: false,
    freeScroll: true,
    contain: true,
  };

  return (
    <Layout>
      <WarnModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <section name="banner" className={indexStyle["banner"]}>
        <div className={indexStyle["banner_container"]}>
          <div className={indexStyle["banner_image"]}>
            <BannerImage />
          </div>
        </div>
      </section>

      <section name="intro" className={indexStyle["intro"]}>
        <div className={indexStyle["intro_container"]}>
          <div className={indexStyle["intro_description"]}>
            <h1>
              Svatební salon Delta přináší zákazníkům z&nbsp;celé České
              republiky exkluzivní módní kolekce z&nbsp;předních světových
              veletrhů. Od roku 1993 patříme mezi nejvýznamější tuzemské
              svatební salony.
            </h1>
          </div>
        </div>
      </section>

      <section name="categories" className={indexStyle["categories"]}>
        <div className={indexStyle["categories_container"]}>
          <Flickity
            className={indexStyle["categories_list"]}
            options={categoriesSliderOptions}
          >
            {collectionsList &&
              collectionsList.map((collection) => (
                <div
                  key={collection.node.id}
                  className={indexStyle["categories_list_item"]}
                >
                  <div className={indexStyle["categories_list_item_image"]}>
                    <Img
                      fluid={
                        collection.node.dressSet.edges[0].node.dresspictureSet
                          .edges[0].node.processedPhotoSharp.childImageSharp
                          .fluid
                      }
                      alt={collection.node.name}
                    />
                  </div>
                  <div className={indexStyle["categories_list_item_title"]}>
                    <StyledLink
                      to={`${collection.node.category.slug}/${collection.node.slug}`}
                    >
                      {collection.node.name}
                    </StyledLink>
                  </div>
                </div>
              ))}
            {/* <div className={indexStyle["categories_list_item"]}>
              <div className={indexStyle["categories_list_item_image"]}>
                <img src={imageDelta2} alt="Pánské obleky" />
              </div>
              <div className={indexStyle["categories_list_item_title"]}>
                <StyledLink to="/svatebni-saty">Pánské obleky</StyledLink>
              </div>
            </div> */}
          </Flickity>
        </div>
      </section>

      {/* <section name="about" className={indexStyle["about"]}>
        <div className={indexStyle["about_container"]}>
          <div className={indexStyle["about_description"]}>
            <h3>
              Pro zkoušení šatů doporučujeme telefonické objednání předem. Bude
              pro vás připravena kabinka a naše asistentka, která vám pomůže
              vybrat ty nejlepší šaty, ve kterých si váš den D skvěle užijete.
            </h3>
            <StyledLink to="/kontakt">Kontakt</StyledLink>
          </div>
          <div className={indexStyle["about_image"]}>
            <img src={imageDelta6} alt="Svatební šaty" />
          </div>
          <div className={indexStyle["about_description"]}>
            <h3>
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi architecto beatae vitae
              dicta sunt explicabo.
            </h3>
          </div>
        </div>
      </section> */}

      <OfferGuide />
    </Layout>
  );
};

export default IndexPage;
